import { cva } from "class-variance-authority";

export type Rounded =
  | "none"
  | "sm"
  | "normal"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "full";

export const RoundedField = {
  type: "select" as const,
  label: "Yuvarlaklık",
  options: [
    {
      value: "none",
      label: "0",
    },
    {
      value: "sm",
      label: "sm",
    },
    {
      value: "normal",
      label: "normal",
    },
    {
      value: "lg",
      label: "lg",
    },
    {
      value: "xl",
      label: "xl",
    },
    {
      value: "2xl",
      label: "2xl",
    },
    {
      value: "3xl",
      label: "3xl",
    },
    {
      value: "full",
      label: "full",
    },
  ],
};

export const roundedVariants = cva(undefined, {
  variants: {
    rounded: {
      none: "rounded-none",
      sm: "rounded-sm",
      normal: "rounded",
      lg: "rounded-lg",
      xl: "rounded-xl",
      "2xl": "rounded-2xl",
      "3xl": "rounded-3xl",
      full: "rounded-full",
    },
  },
});
