import React from "react";

interface BurgerIconProps {
  color?: string;
  distance?: "sm" | "md" | "lg";
  duration?: number;
  easing?: string;
  size?: number;
  toggled: boolean;
}

const BurgerIcon: React.FC<BurgerIconProps> = ({
  color = "currentColor",
  distance = "md",
  duration = 0.2,
  easing = "cubic-bezier(0.4, 0, 0.2, 1)",
  size = 24,
  toggled,
}) => {
  const area = 24;
  const width = Math.max(12, Math.min(area, size));
  const room = Math.round((area - width) / 2);

  const barHeightRaw = width / 12;
  const barHeight = Math.round(barHeightRaw);

  const space = distance === "lg" ? 0.25 : distance === "sm" ? 0.75 : 0.5;
  const marginRaw = width / (3 * (space + 1));
  const margin = Math.round(marginRaw);

  const height = barHeight * 3 + margin * 2;
  const topOffset = Math.round((area - height) / 2);

  const translate =
    distance === "lg" ? 4.0425 : distance === "sm" ? 5.1625 : 4.6325;
  const deviation = (barHeightRaw - barHeight + (marginRaw - margin)) / 1;
  const move = parseFloat((width / translate - deviation / (4 / 3)).toFixed(2));

  const burgerStyles: React.CSSProperties = {
    cursor: "pointer",
    height: `${area}px`,
    position: "relative",
    transition: `${duration}s ${easing}`,
    userSelect: "none",
    width: `${area}px`,
  };

  const barStyles: React.CSSProperties = {
    background: color,
    height: `${barHeight}px`,
    left: `${room}px`,
    position: "absolute",
    borderRadius: "9em",
  };

  const isLeft = false;

  return (
    <div
      style={{
        ...burgerStyles,
        transform: `${
          toggled ? `rotate(${90 * (isLeft ? -1 : 1)}deg)` : "none"
        }`,
      }}
    >
      <div
        style={{
          ...barStyles,
          width: `${width}px`,
          top: `${topOffset}px`,
          transition: `${duration}s ${easing}`,
          transform: `${
            toggled
              ? `rotate(${45 * (isLeft ? -1 : 1)}deg) translate(${move * (isLeft ? -1 : 1)}px, ${move}px)`
              : "none"
          }`,
        }}
      />

      <div
        style={{
          ...barStyles,
          width: `${width}px`,
          top: `${topOffset + barHeight + margin}px`,
          transition: `${duration}s ${easing}`,
          transform: `${toggled ? "scaleX(0)" : "none"}`,
        }}
      />

      <div
        style={{
          ...barStyles,
          width: `${width}px`,
          top: `${topOffset + barHeight * 2 + margin * 2}px`,
          transition: `${duration}s ${easing}`,
          transform: `${
            toggled
              ? `rotate(${45 * (isLeft ? 1 : -1)}deg) translate(${move * (isLeft ? -1 : 1)}px, ${move * -1}px)`
              : "none"
          }`,
        }}
      />
    </div>
  );
};

export default BurgerIcon;
