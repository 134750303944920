import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatFileSize(bytes: number): string {
  if (bytes < 1024) {
    return `${bytes} B`;
  }

  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`;
  }

  if (bytes < 1024 * 1024 * 1024) {
    return `${(bytes / 1024 / 1024).toFixed(2)} MB`;
  }

  return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} GB`;
}

export function formatCurrency(currency: string): string {
  if (currency === "USD") {
    return "Amerikan Doları ($)";
  }

  if (currency === "EUR") {
    return "Euro (€)";
  }

  if (currency === "TRY") {
    return "Türk Lirası (₺)";
  }

  return currency;
}

export function formatMoney(money: {
  amount: number | string;
  currency: string;
}): string {
  const amount =
    typeof money.amount === "string" ? parseFloat(money.amount) : money.amount;

  if (amount === 0) {
    return money.currency === "TRY" ? "Ücretsiz" : "Free";
  }

  if (money.currency === "USD") {
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  if (money.currency === "EUR") {
    return amount.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }

  if (money.currency === "TRY") {
    return amount.toLocaleString("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 0,
    });
  }

  return `${amount} ${money.currency}`;
}

export function hsv2rgb(
  h: number,
  s: number,
  v: number,
): [number, number, number] {
  const normalizedHue = h / 360;
  const i = Math.floor(normalizedHue * 6);
  const f = normalizedHue * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0:
      return [v, t, p];
    case 1:
      return [q, v, p];
    case 2:
      return [p, v, t];
    case 3:
      return [p, q, v];
    case 4:
      return [t, p, v];
    case 5:
      return [v, p, q];
    default:
      return [0, 0, 0];
  }
}

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hsv2hex(h: number, s: number, v: number) {
  const [r, g, b] = hsv2rgb(h, s, v);
  return rgbToHex(
    Math.round(r * 255),
    Math.round(g * 255),
    Math.round(b * 255),
  );
}

export function hex2hsv(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  let h = 0;
  let s = 0;
  const v = max;

  if (delta !== 0) {
    s = delta / max;

    if (r === max) {
      h = (g - b) / delta + (g < b ? 6 : 0);
    } else if (g === max) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }

    h *= 60;
  }

  return {
    hue: h,
    saturation: s,
    value: v,
  };
}

export function hex2hsl(hex: string = "#000000") {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (delta !== 0) {
    s = delta / (1 - Math.abs(max + min - 1));

    if (r === max) {
      h = (g - b) / delta + (g < b ? 6 : 0);
    } else if (g === max) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }

    h /= 6;
  }

  return `${Math.round(h * 360)} ${(s * 100 + 0.5) | 0}% ${(l * 100 + 0.5) | 0}%`;
}
