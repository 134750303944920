import * as radixIcons from "@radix-ui/react-icons";
import { cloneElement, type ReactNode } from "react";
import { CreditCardIcon } from "lucide-react";

export const Icon: React.FC<{
  icon: string | null | undefined;
  className?: string;
  style?: React.CSSProperties;
}> = ({ icon, className, style }) => {
  if (!icon || !icons[icon]) {
    return null;
  }

  return cloneElement(icons[icon] as any, {
    className,
    style,
  });
};

const icons = Object.keys(radixIcons).reduce<Record<string, ReactNode>>(
  (acc, iconName) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof radixIcons[iconName] === "object") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const El = radixIcons[iconName];

      return {
        ...acc,
        [iconName]: <El />,
      };
    }

    return acc;
  },
  {
    CreditCard: <CreditCardIcon />,
  },
);

export const iconOptions = [
  { label: "None", value: false },
  { label: "CreditCard", value: "CreditCard" },
  ...Object.keys(radixIcons).map((iconName) => ({
    label: iconName,
    value: iconName,
  })),
];
