import { cva } from "class-variance-authority";

export type Padding =
  | "none"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl";

export const PaddingField = {
  type: "select" as const,
  label: "Padding",
  options: [
    {
      value: "none",
      label: "0",
    },
    {
      value: "xs",
      label: "xs",
    },
    {
      value: "sm",
      label: "Küçük",
    },
    {
      value: "md",
      label: "Orta",
    },
    {
      value: "lg",
      label: "Büyük",
    },
    {
      value: "xl",
      label: "XL",
    },
    {
      value: "2xl",
      label: "XXL",
    },
    {
      value: "3xl",
      label: "3XL",
    },
    {
      value: "4xl",
      label: "4XL",
    },
    {
      value: "5xl",
      label: "5XL",
    },
    {
      value: "6xl",
      label: "6XL",
    },
    {
      value: "7xl",
      label: "7XL",
    },
    {
      value: "8xl",
      label: "8XL",
    },
    {
      value: "9xl",
      label: "9XL",
    },
  ],
};

export const paddingVariants = cva(undefined, {
  variants: {
    size: {
      none: "",
      sm: "",
      xs: "",
      md: "",
      lg: "",
      xl: "",
      "2xl": "",
      "3xl": "",
      "4xl": "",
      "5xl": "",
      "6xl": "",
      "7xl": "",
      "8xl": "",
      "9xl": "",
    },
    side: {
      left: "",
      right: "",
      top: "",
      bottom: "",
    },
  },
  compoundVariants: [
    {
      size: "none",
      side: "left",
      className: "pl-0",
    },
    {
      size: "none",
      side: "right",
      className: "pr-0",
    },
    {
      size: "none",
      side: "top",
      className: "pt-0",
    },
    {
      size: "none",
      side: "bottom",
      className: "pb-0",
    },
    {
      size: "xs",
      side: "left",
      className: "pl-0.5 sm:pl-1 xl:pl-1.5",
    },
    {
      size: "xs",
      side: "right",
      className: "pr-0.5 sm:pr-1 xl:pr-1.5",
    },
    {
      size: "xs",
      side: "top",
      className: "pt-0.5 sm:pt-1 xl:pt-1.5",
    },
    {
      size: "xs",
      side: "bottom",
      className: "pb-0.5 sm:pb-1 xl:pb-1.5",
    },
    {
      size: "sm",
      side: "left",
      className: "pl-1 sm:pl-1.5 xl:pl-2",
    },
    {
      size: "sm",
      side: "right",
      className: "pr-1 sm:pr-1.5 xl:pr-2",
    },
    {
      size: "sm",
      side: "top",
      className: "pt-1 sm:pt-1.5 xl:pt-2",
    },
    {
      size: "sm",
      side: "bottom",
      className: "pb-1 sm:pb-1.5 xl:pb-2",
    },
    {
      size: "md",
      side: "left",
      className: "pl-2 sm:pl-3 xl:pl-4",
    },
    {
      size: "md",
      side: "right",
      className: "pr-2 sm:pr-3 xl:pr-4",
    },
    {
      size: "md",
      side: "top",
      className: "pt-2 sm:pt-3 xl:pt-4",
    },
    {
      size: "md",
      side: "bottom",
      className: "pb-2 sm:pb-3 xl:pb-4",
    },
    {
      size: "lg",
      side: "left",
      className: "pl-4 sm:pl-6 xl:pl-8",
    },
    {
      size: "lg",
      side: "right",
      className: "pr-4 sm:pr-6 xl:pr-8",
    },
    {
      size: "lg",
      side: "top",
      className: "pt-4 sm:pt-6 xl:pt-8",
    },
    {
      size: "lg",
      side: "bottom",
      className: "pb-4 sm:pb-6 xl:pb-8",
    },
    {
      size: "xl",
      side: "left",
      className: "pl-6 sm:pl-10 lg:pl-12 xl:pl-16",
    },
    {
      size: "xl",
      side: "right",
      className: "pr-6 sm:pr-10 lg:pr-12 xl:pr-16",
    },
    {
      size: "xl",
      side: "top",
      className: "pt-6 sm:pt-10 lg:pt-12 xl:pt-16",
    },
    {
      size: "xl",
      side: "bottom",
      className: "pb-6 sm:pb-10 lg:pb-12 xl:pb-16",
    },
    {
      size: "2xl",
      side: "left",
      className: "pl-8 sm:pl-12 lg:pl-16 xl:pl-20",
    },
    {
      size: "2xl",
      side: "right",
      className: "pr-8 sm:pr-12 lg:pr-16 xl:pr-20",
    },
    {
      size: "2xl",
      side: "top",
      className: "pt-8 sm:pt-12 lg:pt-16 xl:pt-20",
    },
    {
      size: "2xl",
      side: "bottom",
      className: "pb-8 sm:pb-12 lg:pb-16 xl:pb-20",
    },
    {
      size: "3xl",
      side: "left",
      className: "pl-12 sm:pl-16 lg:pl-20 xl:pl-24",
    },
    {
      size: "3xl",
      side: "right",
      className: "pr-12 sm:pr-16 lg:pr-20 xl:pr-24",
    },
    {
      size: "3xl",
      side: "top",
      className: "pt-12 sm:pt-16 lg:pt-20 xl:pt-24",
    },
    {
      size: "3xl",
      side: "bottom",
      className: "pb-12 sm:pb-16 lg:pb-20 xl:pb-24",
    },
    {
      size: "4xl",
      side: "left",
      className: "pl-20 sm:pl-28 lg:pl-36 xl:pl-44",
    },
    {
      size: "4xl",
      side: "right",
      className: "pr-20 sm:pr-28 lg:pr-36 xl:pr-44",
    },
    {
      size: "4xl",
      side: "top",
      className: "pt-20 sm:pt-28 lg:pt-36 xl:pt-44",
    },
    {
      size: "4xl",
      side: "bottom",
      className: "pb-20 sm:pb-28 lg:pb-36 xl:pb-44",
    },
    {
      size: "5xl",
      side: "left",
      className: "pl-24 sm:pl-36 lg:pl-48",
    },
    {
      size: "5xl",
      side: "right",
      className: "pr-24 sm:pr-36 lg:pr-48",
    },
    {
      size: "5xl",
      side: "top",
      className: "pt-24 sm:pt-36 lg:pt-48",
    },
    {
      size: "5xl",
      side: "bottom",
      className: "pb-24 sm:pb-36 lg:pb-48",
    },
    {
      size: "6xl",
      side: "left",
      className: "pl-28 sm:pl-44 lg:pl-64",
    },
    {
      size: "6xl",
      side: "right",
      className: "pr-28 sm:pr-44 lg:pr-64",
    },
    {
      size: "6xl",
      side: "top",
      className: "pt-28 sm:pt-44 lg:pt-64",
    },
    {
      size: "6xl",
      side: "bottom",
      className: "pb-28 sm:pb-44 lg:pb-64",
    },
    {
      size: "7xl",
      side: "left",
      className: "pl-32 sm:pl-52 lg:pl-72",
    },
    {
      size: "7xl",
      side: "right",
      className: "pr-32 sm:pr-52 lg:pr-72",
    },
    {
      size: "7xl",
      side: "top",
      className: "pt-32 sm:pt-52 lg:pt-72",
    },
    {
      size: "7xl",
      side: "bottom",
      className: "pb-32 sm:pb-52 lg:pb-72",
    },
    {
      size: "8xl",
      side: "left",
      className: "pl-36 sm:pl-60 lg:pl-80",
    },
    {
      size: "8xl",
      side: "right",
      className: "pr-36 sm:pr-60 lg:pr-80",
    },
    {
      size: "8xl",
      side: "top",
      className: "pt-36 sm:pt-60 lg:pt-80",
    },
    {
      size: "8xl",
      side: "bottom",
      className: "pb-36 sm:pb-60 lg:pb-80",
    },
    {
      size: "9xl",
      side: "left",
      className: "pl-40 sm:pl-72 lg:pl-96",
    },
    {
      size: "9xl",
      side: "right",
      className: "pr-40 sm:pr-72 lg:pr-96",
    },
    {
      size: "9xl",
      side: "top",
      className: "pt-40 sm:pt-72 lg:pt-96",
    },
    {
      size: "9xl",
      side: "bottom",
      className: "pb-40 sm:pb-72 lg:pb-96",
    },
  ],
  defaultVariants: {},
});
