"use client";

import { CustomField } from "@/website-editor/core";
import { ColorWheelIcon } from "@radix-ui/react-icons";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Slider } from "@/components/ui/slider";
import { ColorWheel } from "@/components/harmony";
import { Input } from "@/components/ui/input";
import React from "react";
import { hex2hsv, hsv2hex } from "@/lib/utils";
import { nanoid } from "nanoid/non-secure";

export type HarmonyValue = Array<{
  hue: number;
  saturation: number;
  value: number;
}>;

const FieldRenderer: React.FC<
  Parameters<CustomField<HarmonyValue>["render"]>[0]
> = ({ id, field, name, value, onChange, readOnly }) => {
  const [key, setKey] = React.useState(nanoid);

  return (
    <div>
      <div className="flex items-center">
        <ColorWheelIcon className="mr-2 inline size-4 text-gray-400" />
        {field.label}
      </div>
      <div className="mt-2 flex items-center space-x-2">
        <Switch
          id={`switch-${id}`}
          checked={value?.length > 0}
          onCheckedChange={(checked) => {
            if (checked) {
              onChange([
                {
                  hue: 0,
                  saturation: 0.8,
                  value: 1,
                },
              ]);
            } else {
              onChange([]);
            }
          }}
        />
        <Label htmlFor={`switch-${id}`}>Rengini Sen Seç</Label>
      </div>
      {value?.length > 0 && (
        <div>
          <div className="flex pt-4">
            <ColorWheel
              key={key}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              className="mr-4 mt-2 max-w-full"
              harmony="analogous"
              radius={96}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={onChange}
              defaultColor={value?.[0]}
              value={value?.[0].value}
              aria-readonly={readOnly}
            />
            <Slider
              id={`slider-${id}`}
              className="data-[orientation='vertical']:h-[190px]"
              max={1}
              min={0}
              step={0.01}
              orientation="vertical"
              value={[value?.[0].value || 1]}
              onValueChange={(v) => {
                onChange([
                  {
                    ...value?.[0],
                    value: v[0],
                  },
                ]);
              }}
            />
          </div>
          <Input
            placeholder="HEX Kodu"
            type="color"
            value={hsv2hex(value[0].hue, value[0].saturation, value[0].value)}
            onChange={(event) => {
              onChange([hex2hsv(event.target.value)]);
              setKey(nanoid());
            }}
          />
        </div>
      )}
    </div>
  );
};

export const HarmonyCustomField = {
  type: "custom",
  render: (props) => <FieldRenderer {...props} />,
} satisfies CustomField<HarmonyValue>;
