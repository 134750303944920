"use client";

import { createContext, useCallback } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { createEnvironment } from "@/relay/environment";
import React from "react";

export const EnvironmentContext = createContext(() => {});

let environment: ReturnType<typeof createEnvironment> | undefined;

function initializeEnvironment() {
  const _store = environment ?? createEnvironment();

  // For server always create a new environment
  if (typeof window === "undefined") return _store;
  // Create the environment once in the client
  if (!environment) environment = _store;

  return _store;
}

const EnvironmentProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // TODO: layout rendering twice causes this to infinitely render https://github.com/vercel/next.js/issues/49115
  // const [environment, setEnvironment] = useState(createEnvironment);
  const environment = initializeEnvironment();

  const resetEnvironment = useCallback(() => {
    // setEnvironment(createEnvironment);
    window.location.reload();
  }, []);

  return (
    <EnvironmentContext.Provider value={resetEnvironment}>
      <RelayEnvironmentProvider environment={environment}>
        {children}
      </RelayEnvironmentProvider>
    </EnvironmentContext.Provider>
  );
};

export default React.memo(EnvironmentProvider);
