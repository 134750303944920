import { cva } from "class-variance-authority";

export type Theme = "light" | "dark";

export const ThemeField = {
  type: "radio" as const,
  label: "Tema",
  options: [
    {
      value: "light",
      label: "Açık",
    },
    {
      value: "dark",
      label: "Koyu",
    },
  ],
};

export const backgroundVariants = cva(undefined, {
  variants: {
    theme: {
      light: "bg-white",
      transparent: "bg-transparent",
      dark: "bg-gray-900",
    },
  },
});

export const textColorVariants = cva(undefined, {
  variants: {
    theme: {
      light: "text-foreground",
      dark: "text-foreground",
    },
  },
});

export const mutedTextColorVariants = cva(undefined, {
  variants: {
    theme: {
      light: "text-foreground/60",
      dark: "text-foreground/60",
    },
  },
});
