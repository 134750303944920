import { cva } from "class-variance-authority";

export type Margin =
  | "none"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl";

export const MarginField = {
  type: "select" as const,
  label: "Margin",
  options: [
    {
      value: "none",
      label: "0",
    },
    {
      value: "xs",
      label: "xs",
    },
    {
      value: "sm",
      label: "Küçük",
    },
    {
      value: "md",
      label: "Orta",
    },
    {
      value: "lg",
      label: "Büyük",
    },
    {
      value: "xl",
      label: "XL",
    },
    {
      value: "2xl",
      label: "XXL",
    },
    {
      value: "3xl",
      label: "3XL",
    },
    {
      value: "4xl",
      label: "4XL",
    },
    {
      value: "5xl",
      label: "5XL",
    },
    {
      value: "6xl",
      label: "6XL",
    },
    {
      value: "7xl",
      label: "7XL",
    },
    {
      value: "8xl",
      label: "8XL",
    },
    {
      value: "9xl",
      label: "9XL",
    },
  ],
};

export const marginVariants = cva(undefined, {
  variants: {
    size: {
      none: "",
      sm: "",
      xs: "",
      md: "",
      lg: "",
      xl: "",
      "2xl": "",
      "3xl": "",
      "4xl": "",
      "5xl": "",
      "6xl": "",
      "7xl": "",
      "8xl": "",
      "9xl": "",
    },
    side: {
      left: "",
      leftNegative: "",
      right: "",
      rightNegative: "",
      top: "",
      topNegative: "",
      bottom: "",
      bottomNegative: "",
    },
  },
  compoundVariants: [
    {
      size: "none",
      side: "left",
      className: "", // keep empty mx-auto
    },
    {
      size: "none",
      side: "right",
      className: "", // keep empty mx-auto
    },
    {
      size: "none",
      side: "top",
      className: "mt-0",
    },
    {
      size: "none",
      side: "bottom",
      className: "mb-0",
    },
    {
      size: "none",
      side: "leftNegative",
      className: "", // keep empty mx-auto
    },
    {
      size: "none",
      side: "rightNegative",
      className: "", // keep empty mx-auto
    },
    {
      size: "none",
      side: "topNegative",
      className: "mt-0",
    },
    {
      size: "none",
      side: "bottomNegative",
      className: "mb-0",
    },
    {
      size: "xs",
      side: "left",
      className: "ml-0.5 sm:ml-1 xl:ml-1.5",
    },
    {
      size: "xs",
      side: "right",
      className: "mr-0.5 sm:mr-1 xl:mr-1.5",
    },
    {
      size: "xs",
      side: "top",
      className: "mt-0.5 sm:mt-1 xl:mt-1.5",
    },
    {
      size: "xs",
      side: "bottom",
      className: "mb-0.5 sm:mb-1 xl:mb-1.5",
    },
    {
      size: "xs",
      side: "leftNegative",
      className: "-ml-0.5 sm:-ml-1 xl:-ml-1.5",
    },
    {
      size: "xs",
      side: "rightNegative",
      className: "-mr-0.5 sm:-mr-1 xl:-mr-1.5",
    },
    {
      size: "xs",
      side: "topNegative",
      className: "-mt-0.5 sm:-mt-1 xl:-mt-1.5",
    },
    {
      size: "xs",
      side: "bottomNegative",
      className: "-mb-0.5 sm:-mb-1 xl:-mb-1.5",
    },
    {
      size: "sm",
      side: "left",
      className: "ml-1 sm:ml-1.5 xl:ml-2",
    },
    {
      size: "sm",
      side: "right",
      className: "mr-1 sm:mr-1.5 xl:mr-2",
    },
    {
      size: "sm",
      side: "top",
      className: "mt-1 sm:mt-1.5 xl:mt-2",
    },
    {
      size: "sm",
      side: "bottom",
      className: "mb-1 sm:mb-1.5 xl:mb-2",
    },
    {
      size: "sm",
      side: "leftNegative",
      className: "-ml-1 sm:-ml-1.5 xl:-ml-2",
    },
    {
      size: "sm",
      side: "rightNegative",
      className: "-mr-1 sm:-mr-1.5 xl:-mr-2",
    },
    {
      size: "sm",
      side: "topNegative",
      className: "-mt-1 sm:-mt-1.5 xl:-mt-2",
    },
    {
      size: "sm",
      side: "bottomNegative",
      className: "-mb-1 sm:-mb-1.5 xl:-mb-2",
    },
    {
      size: "md",
      side: "left",
      className: "ml-2 sm:ml-3 xl:ml-4",
    },
    {
      size: "md",
      side: "right",
      className: "mr-2 sm:mr-3 xl:mr-4",
    },
    {
      size: "md",
      side: "top",
      className: "mt-2 sm:mt-3 xl:mt-4",
    },
    {
      size: "md",
      side: "bottom",
      className: "mb-2 sm:mb-3 xl:mb-4",
    },
    {
      size: "md",
      side: "leftNegative",
      className: "-ml-2 sm:-ml-3 xl:-ml-4",
    },
    {
      size: "md",
      side: "rightNegative",
      className: "-mr-2 sm:-mr-3 xl:-mr-4",
    },
    {
      size: "md",
      side: "topNegative",
      className: "-mt-2 sm:-mt-3 xl:-mt-4",
    },
    {
      size: "md",
      side: "bottomNegative",
      className: "-mb-2 sm:-mb-3 xl:-mb-4",
    },
    {
      size: "lg",
      side: "left",
      className: "ml-4 sm:ml-6 xl:ml-8",
    },
    {
      size: "lg",
      side: "right",
      className: "mr-4 sm:mr-6 xl:mr-8",
    },
    {
      size: "lg",
      side: "top",
      className: "mt-4 sm:mt-6 xl:mt-8",
    },
    {
      size: "lg",
      side: "bottom",
      className: "mb-4 sm:mb-6 xl:mb-8",
    },
    {
      size: "lg",
      side: "leftNegative",
      className: "-ml-4 sm:-ml-6 xl:-ml-8",
    },
    {
      size: "lg",
      side: "rightNegative",
      className: "-mr-4 sm:-mr-6 xl:-mr-8",
    },
    {
      size: "lg",
      side: "topNegative",
      className: "-mt-4 sm:-mt-6 xl:-mt-8",
    },
    {
      size: "lg",
      side: "bottomNegative",
      className: "-mb-4 sm:-mb-6 xl:-mb-8",
    },
    {
      size: "xl",
      side: "left",
      className: "ml-6 sm:ml-10 lg:ml-12 xl:ml-16",
    },
    {
      size: "xl",
      side: "right",
      className: "mr-6 sm:mr-10 lg:mr-12 xl:mr-16",
    },
    {
      size: "xl",
      side: "top",
      className: "mt-6 sm:mt-10 lg:mt-12 xl:mt-16",
    },
    {
      size: "xl",
      side: "bottom",
      className: "mb-6 sm:mb-10 lg:mb-12 xl:mb-16",
    },
    {
      size: "xl",
      side: "leftNegative",
      className: "-ml-6 sm:-ml-10 lg:-ml-12 xl:-ml-16",
    },
    {
      size: "xl",
      side: "rightNegative",
      className: "-mr-6 sm:-mr-10 lg:-mr-12 xl:-mr-16",
    },
    {
      size: "xl",
      side: "topNegative",
      className: "-mt-6 sm:-mt-10 lg:-mt-12 xl:-mt-16",
    },
    {
      size: "xl",
      side: "bottomNegative",
      className: "-mb-6 sm:-mb-10 lg:-mb-12 xl:-mb-16",
    },
    {
      size: "2xl",
      side: "left",
      className: "ml-8 sm:ml-12 lg:ml-16 xl:ml-20",
    },
    {
      size: "2xl",
      side: "right",
      className: "mr-8 sm:mr-12 lg:mr-16 xl:mr-20",
    },
    {
      size: "2xl",
      side: "top",
      className: "mt-8 sm:mt-12 lg:mt-16 xl:mt-20",
    },
    {
      size: "2xl",
      side: "bottom",
      className: "mb-8 sm:mb-12 lg:mb-16 xl:mb-20",
    },
    {
      size: "2xl",
      side: "leftNegative",
      className: "-ml-8 sm:-ml-12 lg:-ml-16 xl:-ml-20",
    },
    {
      size: "2xl",
      side: "rightNegative",
      className: "-mr-8 sm:-mr-12 lg:-mr-16 xl:-mr-20",
    },
    {
      size: "2xl",
      side: "topNegative",
      className: "-mt-8 sm:-mt-12 lg:-mt-16 xl:-mt-20",
    },
    {
      size: "2xl",
      side: "bottomNegative",
      className: "-mb-8 sm:-mb-12 lg:-mb-16 xl:-mb-20",
    },
    {
      size: "3xl",
      side: "left",
      className: "ml-12 sm:ml-16 lg:ml-20 xl:ml-24",
    },
    {
      size: "3xl",
      side: "right",
      className: "mr-12 sm:mr-16 lg:mr-20 xl:mr-24",
    },
    {
      size: "3xl",
      side: "top",
      className: "mt-12 sm:mt-16 lg:mt-20 xl:mt-24",
    },
    {
      size: "3xl",
      side: "bottom",
      className: "mb-12 sm:mb-16 lg:mb-20 xl:mb-24",
    },
    {
      size: "3xl",
      side: "leftNegative",
      className: "-ml-12 sm:-ml-16 lg:-ml-20 xl:-ml-24",
    },
    {
      size: "3xl",
      side: "rightNegative",
      className: "-mr-12 sm:-mr-16 lg:-mr-20 xl:-mr-24",
    },
    {
      size: "3xl",
      side: "topNegative",
      className: "-mt-12 sm:-mt-16 lg:-mt-20 xl:-mt-24",
    },
    {
      size: "3xl",
      side: "bottomNegative",
      className: "-mb-12 sm:-mb-16 lg:-mb-20 xl:-mb-24",
    },
    {
      size: "4xl",
      side: "left",
      className: "ml-20 sm:ml-28 lg:ml-36 xl:ml-44",
    },
    {
      size: "4xl",
      side: "right",
      className: "mr-20 sm:mr-28 lg:mr-36 xl:mr-44",
    },
    {
      size: "4xl",
      side: "top",
      className: "mt-20 sm:mt-28 lg:mt-36 xl:mt-44",
    },
    {
      size: "4xl",
      side: "bottom",
      className: "mb-20 sm:mb-28 lg:mb-36 xl:mb-44",
    },
    {
      size: "4xl",
      side: "leftNegative",
      className: "-ml-20 sm:-ml-28 lg:-ml-36 xl:-ml-44",
    },
    {
      size: "4xl",
      side: "rightNegative",
      className: "-mr-20 sm:-mr-28 lg:-mr-36 xl:-mr-44",
    },
    {
      size: "4xl",
      side: "topNegative",
      className: "-mt-20 sm:-mt-28 lg:-mt-36 xl:-mt-44",
    },
    {
      size: "4xl",
      side: "bottomNegative",
      className: "-mb-20 sm:-mb-28 lg:-mb-36 xl:-mb-44",
    },
    {
      size: "5xl",
      side: "left",
      className: "ml-24 sm:ml-36 lg:ml-48",
    },
    {
      size: "5xl",
      side: "right",
      className: "mr-24 sm:mr-36 lg:mr-48",
    },
    {
      size: "5xl",
      side: "top",
      className: "mt-24 sm:mt-36 lg:mt-48",
    },
    {
      size: "5xl",
      side: "bottom",
      className: "mb-24 sm:mb-36 lg:mb-48",
    },
    {
      size: "5xl",
      side: "leftNegative",
      className: "-ml-24 sm:-ml-36 lg:-ml-48",
    },
    {
      size: "5xl",
      side: "rightNegative",
      className: "-mr-24 sm:-mr-36 lg:-mr-48",
    },
    {
      size: "5xl",
      side: "topNegative",
      className: "-mt-24 sm:-mt-36 lg:-mt-48",
    },
    {
      size: "5xl",
      side: "bottomNegative",
      className: "-mb-24 sm:-mb-36 lg:-mb-48",
    },
    {
      size: "6xl",
      side: "left",
      className: "ml-28 sm:ml-44 lg:ml-64",
    },
    {
      size: "6xl",
      side: "right",
      className: "mr-28 sm:mr-44 lg:mr-64",
    },
    {
      size: "6xl",
      side: "top",
      className: "mt-28 sm:mt-44 lg:mt-64",
    },
    {
      size: "6xl",
      side: "bottom",
      className: "mb-28 sm:mb-44 lg:mb-64",
    },
    {
      size: "6xl",
      side: "leftNegative",
      className: "-ml-28 sm:-ml-44 lg:-ml-64",
    },
    {
      size: "6xl",
      side: "rightNegative",
      className: "-mr-28 sm:-mr-44 lg:-mr-64",
    },
    {
      size: "6xl",
      side: "topNegative",
      className: "-mt-28 sm:-mt-44 lg:-mt-64",
    },
    {
      size: "6xl",
      side: "bottomNegative",
      className: "-mb-28 sm:-mb-44 lg:-mb-64",
    },
    {
      size: "7xl",
      side: "left",
      className: "ml-32 sm:ml-52 lg:ml-72",
    },
    {
      size: "7xl",
      side: "right",
      className: "mr-32 sm:mr-52 lg:mr-72",
    },
    {
      size: "7xl",
      side: "top",
      className: "mt-32 sm:mt-52 lg:mt-72",
    },
    {
      size: "7xl",
      side: "bottom",
      className: "mb-32 sm:mb-52 lg:mb-72",
    },
    {
      size: "7xl",
      side: "leftNegative",
      className: "-ml-32 sm:-ml-52 lg:-ml-72",
    },
    {
      size: "7xl",
      side: "rightNegative",
      className: "-mr-32 sm:-mr-52 lg:-mr-72",
    },
    {
      size: "7xl",
      side: "topNegative",
      className: "-mt-32 sm:-mt-52 lg:-mt-72",
    },
    {
      size: "7xl",
      side: "bottomNegative",
      className: "-mb-32 sm:-mb-52 lg:-mb-72",
    },
    {
      size: "8xl",
      side: "left",
      className: "ml-36 sm:ml-60 lg:ml-80",
    },
    {
      size: "8xl",
      side: "right",
      className: "mr-36 sm:mr-60 lg:mr-80",
    },
    {
      size: "8xl",
      side: "top",
      className: "mt-36 sm:mt-60 lg:mt-80",
    },
    {
      size: "8xl",
      side: "bottom",
      className: "mb-36 sm:mb-60 lg:mb-80",
    },
    {
      size: "8xl",
      side: "leftNegative",
      className: "-ml-36 sm:-ml-60 lg:-ml-80",
    },
    {
      size: "8xl",
      side: "rightNegative",
      className: "-mr-36 sm:-mr-60 lg:-mr-80",
    },
    {
      size: "8xl",
      side: "topNegative",
      className: "-mt-36 sm:-mt-60 lg:-mt-80",
    },
    {
      size: "8xl",
      side: "bottomNegative",
      className: "-mb-36 sm:-mb-60 lg:-mb-80",
    },
    {
      size: "9xl",
      side: "left",
      className: "ml-40 sm:ml-72 lg:ml-96",
    },
    {
      size: "9xl",
      side: "right",
      className: "mr-40 sm:mr-72 lg:mr-96",
    },
    {
      size: "9xl",
      side: "top",
      className: "mt-40 sm:mt-72 lg:mt-96",
    },
    {
      size: "9xl",
      side: "bottom",
      className: "mb-40 sm:mb-72 lg:mb-96",
    },
    {
      size: "9xl",
      side: "leftNegative",
      className: "-ml-40 sm:-ml-72 lg:-ml-96",
    },
    {
      size: "9xl",
      side: "rightNegative",
      className: "-mr-40 sm:-mr-72 lg:-mr-96",
    },
    {
      size: "9xl",
      side: "topNegative",
      className: "-mt-40 sm:-mt-72 lg:-mt-96",
    },
    {
      size: "9xl",
      side: "bottomNegative",
      className: "-mb-40 sm:-mb-72 lg:-mb-96",
    },
  ],
  defaultVariants: {},
});
