import { Icon, iconOptions } from "@/website-editor/custom-fields/icon";
import { ComponentConfig } from "@/website-editor/core";
import { Button as ButtonComponent } from "@/components/ui/button";
import { cn, hsv2hex } from "@/lib/utils";
import PiLink from "@/components/PiLink";
import {
  HarmonyCustomField,
  HarmonyValue,
} from "@/website-editor/custom-fields/harmony";
import {
  JustifyContent,
  JustifyContentField,
  justifyContentVariants,
} from "@/website-editor/custom-fields/justifyContent";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import type { Field } from "@/website-editor/core/types/Fields";

export type ButtonProps = {
  label: string;
  url: string;
  variant: "default" | "outline" | "link";
  size: "default" | "sm" | "lg";
  startIcon?: string | false;
  endIcon?: string | false;
  customColor?: any;
  customTextColor?: any;
};

export const Button = {
  displayName: "Buton",
  fields: {
    startIcon: {
      type: "select",
      label: "Sol İkon",
      options: iconOptions,
    },
    endIcon: {
      type: "select",
      label: "Sağ İkon",
      options: iconOptions,
    },
    label: { type: "textarea", label: "Buton Metni" },
    url: { type: "text", label: "URL" },
    variant: {
      type: "radio",
      label: "Stil",
      options: [
        { label: "Default", value: "default" },
        { label: "Outline", value: "outline" },
        { label: "Link", value: "link" },
      ],
    },
    size: {
      type: "radio",
      label: "Boyut",
      options: [
        { label: "Büyük", value: "lg" },
        { label: "Normal", value: "default" },
        { label: "Küçük", value: "sm" },
      ],
    },
    customColor: {
      ...HarmonyCustomField,
      label: "Özel Buton Arkaplan Rengi",
    },
    customTextColor: {
      ...HarmonyCustomField,
      label: "Özel Buton Yazı Rengi",
    },
  },
  defaultProps: {
    label: "Button",
    url: "#",
    variant: "default",
    size: "lg",
    customColor: [],
  },
  render: ({
    label,
    url,
    variant,
    size,
    startIcon,
    endIcon,
    customColor = [],
  }) => {
    if (!url) return <></>;

    return (
      <ButtonComponent
        asChild
        variant={variant}
        size={size}
        className={cn({
          "bg-unset": customColor?.length > 0,
          "px-0 sm:px-0": variant === "link",
        })}
        style={
          customColor?.length > 0
            ? {
                backgroundColor: hsv2hex(
                  customColor[0].hue,
                  customColor[0].saturation,
                  customColor[0].value,
                ),
              }
            : {}
        }
      >
        <PiLink href={url}>
          <Icon icon={startIcon || null} className="mr-2 size-3 sm:size-4" />
          {label}
          <Icon icon={endIcon || null} className="ml-2 size-3 sm:size-4" />
        </PiLink>
      </ButtonComponent>
    );
  },
} satisfies ComponentConfig<ButtonProps>;

export const ButtonsArrayField = {
  type: "array",
  label: "Butonlar",
  getItemSummary: (item) => item.label || "Buton",
  arrayFields: Button.fields,
  defaultItemProps: Button.defaultProps,
} satisfies Field<Array<ButtonProps>>;

export type ButtonsProps = {
  buttons: Array<ButtonProps>;
  justifyContent: JustifyContent;
  theme: Theme;
  harmony: HarmonyValue;
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const Buttons = {
  displayName: "Butonlar",
  fields: {
    buttons: {
      ...ButtonsArrayField,
    },
    justifyContent: {
      ...JustifyContentField,
      label: "Butonları Hizala",
    },
    theme: ThemeField,
    harmony: {
      ...HarmonyCustomField,
      label: "Arkaplan Rengi",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    buttons: [Button.defaultProps],
    justifyContent: "start",
    theme: "light",
    harmony: [],
    paddingTop: "lg",
    paddingBottom: "lg",
    marginLeft: "none",
    marginRight: "none",
  },
  render: ({
    buttons,
    theme,
    harmony,
    justifyContent,
    paddingTop,
    paddingBottom,
    marginLeft,
    marginRight,
  }) => {
    return (
      <ButtonsComponent
        buttons={buttons}
        on={theme}
        className={cn(
          "max-w-7xl mx-auto",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: marginLeft }),
          paddingVariants({ side: "right", size: marginRight }),
          justifyContentVariants({ justifyContent }),
        )}
        style={
          harmony[0]
            ? {
                backgroundColor: hsv2hex(
                  harmony[0].hue,
                  harmony[0].saturation,
                  harmony[0].value,
                ),
              }
            : undefined
        }
      />
    );
  },
} satisfies ComponentConfig<ButtonsProps>;

export const ButtonsComponent: React.FC<
  React.PropsWithChildren<{
    buttons: Array<ButtonProps>;
    className?: string;
    style?: React.CSSProperties;
    on?: "dark" | "light" | null;
  }>
> = ({ buttons, className, style, on, children }) => {
  if (!buttons?.length && !children) return null;

  return (
    <div
      className={cn(
        "flex flex-wrap items-center justify-center gap-x-4 gap-y-2 sm:gap-x-6",
        className,
      )}
      style={style}
    >
      {buttons
        ?.filter((b) => b.url)
        .map(
          (
            {
              url,
              variant,
              label,
              size,
              startIcon,
              endIcon,
              customColor,
              customTextColor,
            },
            idx,
          ) => (
            <ButtonComponent
              asChild
              key={idx + label}
              variant={variant}
              size={size}
              on={on}
              className={cn({
                "bg-unset": customColor?.length > 0,
                "px-0 sm:px-0": variant === "link",
              })}
              style={{
                ...(customColor?.length > 0
                  ? {
                      backgroundColor: hsv2hex(
                        customColor[0].hue,
                        customColor[0].saturation,
                        customColor[0].value,
                      ),
                    }
                  : {}),
                ...(customTextColor?.length > 0
                  ? {
                      color: hsv2hex(
                        customTextColor[0].hue,
                        customTextColor[0].saturation,
                        customTextColor[0].value,
                      ),
                    }
                  : {}),
              }}
            >
              <PiLink href={url}>
                <Icon
                  icon={startIcon || null}
                  className="mr-2 size-3 sm:size-4"
                />
                {label}
                <Icon
                  icon={endIcon || null}
                  className="ml-2 size-3 sm:size-4"
                />
              </PiLink>
            </ButtonComponent>
          ),
        )}
      {children}
    </div>
  );
};
