const PiLogo: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g
      transform="matrix(0.21077601611614227, 0, 0, -0.2191760241985321, -563.165771484375, 1669.9038085937498)"
      fill="currentColor"
      stroke="none"
    >
      <path d="M4960 7570 c-1008 -393 -1842 -712 -2213 -845 -57 -21 -77 -32 -75 -44 3 -18 174 -76 743 -256 187 -59 348 -114 358 -122 15 -14 17 -37 17 -185 0 -140 3 -170 15 -174 8 -3 59 5 112 19 531 138 1384 165 2068 66 154 -23 233 -39 343 -71 40 -12 76 -18 80 -14 4 4 9 87 12 184 l5 176 180 59 c99 32 302 97 450 144 477 150 475 149 475 172 0 20 16 13 -365 158 -137 52 -306 116 -375 142 -141 54 -747 288 -1220 471 -348 135 -446 170 -469 169 -9 0 -72 -22 -141 -49z" />
      <path d="M4350 5740 c-375 -31 -638 -213 -741 -513 -33 -96 -61 -250 -57 -310 l3 -42 55 0 55 0 5 35 c4 20 25 75 48 122 69 142 190 246 342 293 38 12 106 19 217 22 143 5 163 4 169 -10 3 -9 1 -65 -6 -124 -6 -59 -14 -133 -16 -163 -18 -184 -73 -507 -120 -698 -71 -296 -147 -462 -347 -761 -126 -189 -152 -246 -151 -341 0 -121 55 -214 158 -268 46 -24 61 -27 156 -27 99 0 110 2 171 32 147 73 230 217 284 498 27 137 79 545 95 745 6 74 19 212 30 305 10 94 26 269 35 390 20 274 32 402 40 415 4 6 118 10 316 10 249 0 311 -3 315 -13 7 -19 -47 -578 -71 -737 -3 -22 -7 -48 -20 -165 -2 -16 -16 -174 -31 -350 -41 -500 -28 -679 63 -862 90 -184 240 -278 440 -276 184 2 325 71 434 212 96 124 148 267 174 476 21 164 20 173 -9 181 -48 12 -63 -3 -86 -79 -30 -99 -64 -161 -118 -219 -66 -71 -130 -102 -212 -102 -132 0 -224 84 -265 241 -29 111 -16 499 34 998 35 345 41 406 51 544 6 74 13 138 16 143 3 4 158 8 345 8 l339 0 0 184 c0 159 -2 185 -17 197 -13 12 -185 14 -1022 15 -553 1 -1049 -2 -1101 -6z" />
    </g>
  </svg>
);

export default PiLogo;
