// src/index.tsx
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";

// src/utils.ts
var harmonies = {
  triad: [120, 240],
  tetradic: [60, 180, 240],
  complementary: [180],
  analogous: [-30, 30],
  square: [90, 180, 270],
};
var xy2polar = (x, y) => {
  let r = Math.sqrt(x * x + y * y);
  let phi = Math.atan2(y, x);
  return [r, phi];
};
var polar2xy = (r, phi) => {
  let x = r * Math.cos(phi);
  let y = r * Math.sin(phi);
  return [x, y];
};
var rad2deg = (rad) => {
  return ((rad + Math.PI) / (2 * Math.PI)) * 360;
};
var deg2rad = (hue) => {
  return hue * (Math.PI / 180);
};
var hsv2rgb = (hue, saturation, value) => {
  let chroma = value * saturation;
  let hue1 = hue / 60;
  let x = chroma * (1 - Math.abs((hue1 % 2) - 1));
  let r1 = 0,
    g1 = 0,
    b1 = 0;
  if (hue1 >= 0 && hue1 <= 1) {
    [r1, g1, b1] = [chroma, x, 0];
  } else if (hue1 >= 1 && hue1 <= 2) {
    [r1, g1, b1] = [x, chroma, 0];
  } else if (hue1 >= 2 && hue1 <= 3) {
    [r1, g1, b1] = [0, chroma, x];
  } else if (hue1 >= 3 && hue1 <= 4) {
    [r1, g1, b1] = [0, x, chroma];
  } else if (hue1 >= 4 && hue1 <= 5) {
    [r1, g1, b1] = [x, 0, chroma];
  } else if (hue1 >= 5 && hue1 <= 6) {
    [r1, g1, b1] = [chroma, 0, x];
  }
  let m = value - chroma;
  let [r, g, b] = [r1 + m, g1 + m, b1 + m];
  return [255 * r, 255 * g, 255 * b];
};
var xy2rgb = (x, y, radius, value = 1) => {
  x -= radius;
  y -= radius;
  const [r, phi] = xy2polar(x, y);
  const hue = rad2deg(phi);
  const saturation = r / radius;
  return hsv2rgb(hue, saturation, value);
};
var hsv2xy = (hue, saturation, value, radius) => {
  const adjustedHue = hue - 180;
  const [r, phi] = polar2xy(radius * saturation, deg2rad(adjustedHue));
  return {
    x: r + radius,
    y: phi + radius,
  };
};

// src/index.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var ColorWheel = ({
  radius,
  harmony: harmonyName,
  color,
  defaultColor,
  onChange,
  value = 1,
  ...props
}) => {
  const ref = useRef(null);
  const [position, setPosition] = useState(
    defaultColor
      ? hsv2xy(
          defaultColor.hue,
          defaultColor.saturation,
          defaultColor.value,
          radius,
        )
      : hsv2xy(0, 1, value, radius),
  );
  const harmony = useMemo(
    () => harmonies[harmonyName],
    [harmonies, harmonyName],
  );
  const drawCircle = useCallback(
    (ctx) => {
      let image = ctx.createImageData(2 * radius, 2 * radius);
      let data = image.data;
      for (let x = -radius; x < radius; x++) {
        for (let y = -radius; y < radius; y++) {
          let [r2, phi] = xy2polar(x, y);
          let deg = rad2deg(phi);
          let rowLength = 2 * radius;
          let adjustedX = x + radius;
          let adjustedY = y + radius;
          let pixelWidth = 4;
          let index = (adjustedX + adjustedY * rowLength) * pixelWidth;
          let hue = deg;
          let saturation = r2 / radius;
          let [red, green, blue] = hsv2rgb(hue, saturation, value);
          let alpha = 255;
          data[index] = red;
          data[index + 1] = green;
          data[index + 2] = blue;
          data[index + 3] = alpha;
        }
      }
      ctx.putImageData(image, 0, 0);
    },
    [radius, value],
  );
  useEffect(() => {
    if (!ref.current) return;
    const ctx = ref.current.getContext("2d");
    if (!ctx) return;
    ctx.canvas.width = radius * 2;
    ctx.canvas.height = radius * 2;
    drawCircle(ctx);
  }, [drawCircle]);
  useEffect(() => {
    if (color) {
      setPosition(hsv2xy(color.hue, color.saturation, color.value, radius));
    }
  }, [color, radius]);
  const handleDrag = useCallback(
    (e, data) => {
      if (!ref.current) return;
      e.stopPropagation();
      e.preventDefault();
      let [r2, phi] = xy2polar(data.x - radius, data.y - radius);
      r2 = Math.min(r2, radius);
      const [x, y] = polar2xy(r2, phi);
      setPosition({ x: x + radius, y: y + radius });
    },
    [radius],
  );
  const harmonyPairs = useMemo(() => {
    const x = position.x - radius;
    const y = position.y - radius;
    const [r2, phi] = xy2polar(x, y);
    const hue = rad2deg(phi);
    const saturation = r2 / radius;
    const colors = harmony.map((harmonyHue) => {
      let newHue = (hue + harmonyHue) % 360;
      newHue = newHue < 0 ? 360 + newHue : newHue;
      const [x2, y2] = polar2xy(r2, newHue * (Math.PI / 180));
      return {
        x: -x2 + radius,
        y: -y2 + radius,
        hue: newHue,
        saturation,
        value,
      };
    });
    onChange == null
      ? void 0
      : onChange([{ hue, saturation, value }, ...colors]);
    return colors;
  }, [position, harmony, polar2xy, onChange, xy2polar, rad2deg, radius, value]);
  const [r, g, b] = useMemo(
    () => xy2rgb(position.x, position.y, radius, value),
    [position, radius, value],
  );
  return /* @__PURE__ */ jsxs("div", {
    style: {
      position: "relative",
      width: `${radius * 2}px`,
      height: `${radius * 2}px`,
    },
    ...props,
    children: [
      /* @__PURE__ */ jsx("canvas", {
        ref,
        style: {
          width: "100%",
          height: "100%",
          borderRadius: "9999px",
        },
      }),
      harmonyPairs.map((harmony2, i) => {
        const [r2, g2, b2] = hsv2rgb(
          harmony2.hue,
          harmony2.saturation,
          harmony2.value,
        );
        return /* @__PURE__ */ jsx(
          "div",
          {
            style: {
              position: "absolute",
              top: "-12px",
              left: "-12px",
              width: "24px",
              height: "24px",
              borderRadius: "999px",
              border: "2px solid #fff",
              backgroundColor: `rgb(${r2}, ${g2}, ${b2})`,
              transform: `translate(${harmony2.x}px, ${harmony2.y}px)`,
              boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.05)",
            },
          },
          i,
        );
      }),
      /* @__PURE__ */ jsx(Draggable, {
        onDrag: handleDrag,
        position,
        children: /* @__PURE__ */ jsx("div", {
          style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "-12px",
            left: "-12px",
            width: "24px",
            height: "24px",
            borderRadius: "99px",
            border: "2px solid rgba(255, 255, 255, 1)",
            backgroundColor: `rgb(${r}, ${g}, ${b})`,
            boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.05)",
          },
          children: /* @__PURE__ */ jsx("div", {
            style: {
              position: "absolute",
              width: "4px",
              height: "4px",
              borderRadius: "99px",
              backgroundColor: "#fff",
            },
          }),
        }),
      }),
    ],
  });
};
export {
  ColorWheel,
  deg2rad,
  harmonies,
  hsv2rgb,
  hsv2xy,
  polar2xy,
  rad2deg,
  xy2polar,
  xy2rgb,
};
