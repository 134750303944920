"use client";

import Link from "next/link";
import React, { ComponentProps } from "react";
import { useOrganization } from "@/student/context/OrganizationContext";

/**
 * Link component that handles subdomain/custom domain routing for internal links.
 * For example, lets assume href prop is `/pi/dersler/ogren/math101/2020-2021-guz/derse-baslarken`
 * 1. If the user is on pi.education the link will be `/pi/dersler/ogren/math101/2020-2021-guz/derse-baslarken`
 * 2. If the user is on subdomain pi.pi.education the link will be `/dersler/ogren/math101/2020-2021-guz/derse-baslarken`
 * 3. If the user is on custom domain pi.com the link will be `/dersler/ogren/math101/2020-2021-guz/derse-baslarken`
 * @param href The href prop of the link
 * @param children
 * @param props
 */
const PiLink = React.forwardRef<
  HTMLAnchorElement,
  ComponentProps<typeof Link> & {
    includeOrganizationSlugSuffix?: boolean;
  }
>(({ href, children, includeOrganizationSlugSuffix = true, ...props }, ref) => {
  const organization = useOrganization();
  const path = typeof href === "string" ? href : (href.pathname ?? href.href)!;

  if (path.startsWith("//") || !path.startsWith("/")) {
    return (
      <Link
        ref={ref}
        href={href}
        target={
          path.startsWith("#") || path.includes("//pi.education")
            ? undefined
            : "_blank"
        }
        prefetch={false}
        {...props}
      >
        {children}
      </Link>
    );
  }

  let url = href;
  const slug = organization.slug === "blogs" ? "blog" : organization.slug;
  if (
    slug !== "pi" && // TODO: bunun daha iyi bi yolu var mı?
    includeOrganizationSlugSuffix &&
    !path.startsWith(`/${slug}`)
  ) {
    url =
      typeof href === "string"
        ? `/${slug}${href}`
        : { ...href, pathname: `/${slug}${href.pathname}` };
  }
  const host = globalThis.location?.host;

  if (
    !host ||
    host.includes("localhost") ||
    host === "pi.education" ||
    host === "www.pi.education"
  ) {
    return (
      <Link href={url} ref={ref} prefetch={false} {...props}>
        {children}
      </Link>
    );
  }

  // we are either on a subdomain or a custom domain, remove the organization slug (first path segment) from the href
  if (typeof url === "string") {
    const [_, __, ...rest] = url.split("/");

    return (
      <Link {...props} ref={ref} href={`/${rest.join("/")}`} prefetch={false}>
        {children}
      </Link>
    );
  }

  const [_, __, ...rest] = url.pathname!.split("/");

  return (
    <Link
      {...props}
      href={{ ...url, pathname: `/${rest.join("/")}` }}
      prefetch={false}
      ref={ref}
    >
      {children}
    </Link>
  );
});

PiLink.displayName = "PiLink";

export default PiLink;
