import { cva } from "class-variance-authority";

export type JustifyContent =
  | "normal"
  | "start"
  | "end"
  | "center"
  | "between"
  | "around"
  | "evenly"
  | "stretch";

export const JustifyContentField = {
  type: "select" as const,
  label: "Ana Eksende İçerik Pozisyonu",
  options: [
    { value: "normal", label: "Normal" },
    { value: "start", label: "Başlangıç" },
    { value: "center", label: "Orta" },
    { value: "end", label: "Son" },
    { value: "between", label: "Boşluğu içeriğin arasına eşit olarak dağıt" },
    {
      value: "around",
      label:
        "Boşluğu Her İçeriğin iki tarafında eşit boşluk olacak şekilde dağıt",
    },
    { value: "evenly", label: "Boşluğu Eşit Dağıt" },
    {
      value: "stretch",
      label: "İçerikleri tüm boşluğu dolduracak şekilde büyüt",
    },
  ],
};

export const justifyContentVariants = cva(undefined, {
  variants: {
    justifyContent: {
      normal: "justify-normal",
      start: "justify-start",
      end: "justify-end",
      center: "justify-center",
      between: "justify-between",
      around: "justify-around",
      evenly: "justify-evenly",
      stretch: "justify-stretch",
    },
  },
  defaultVariants: {
    justifyContent: "center",
  },
});
